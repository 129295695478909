import { requestHttp } from "../utils/HttpRequest";

export const getTopClientsBySeller = async (idUser, date, idr) => {
  try {
    const response = await requestHttp({
      endpoint: "top_clients_by_seller", // Aquí debe coincidir con el endpoint del backend
      data: {
        user_id: idUser,
        chosen_date: date,
        idr: idr,
      },
    });
    return response;
  } catch (error) {
    console.error("getTopClientsBySeller error", error);
    throw error;
  }
};
