import styled from "styled-components";

export const TopCustomersContainer = styled.section`
  margin: 30px 0 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DataGraphWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const DataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const GraphContainer = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DonutChart = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: ${(props) =>
    `conic-gradient(${props.gradient})`};
  position: relative;
`;

export const DonutLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.9em;
  color: #333;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
`;

export const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;