import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Bar } from "react-chartjs-2";

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    /*legend: {
      position: "left",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },*/
  },
};

export const GraphTopCustomers = ({ topSelling, lang }) => {
  const [data, setData] = useState({});
  const intl = useIntl()

  useEffect(() => {
    loadGrahpData();
  }, [topSelling, lang]);

  const loadGrahpData = () => {
    const labels = [];
    const values = [];
    const backgroundColor = [];
    const borderColor = [];
    if (topSelling) { 
      for (let i = 0; i < topSelling.length; i++) {
        const item = topSelling[i];
        labels.push(
          item.customer_name.length > 20
            ? `${item.customer_name.substring(0, 20)} ...`
            : item.customer_name
        );
        values.push(item.total_sales);
        backgroundColor.push("rgba(94, 148, 248, 1)");
        borderColor.push("rgba(94, 148, 248, 1)");
      }
      const templateData = {
        labels: labels,
        datasets: [
          {
            label: intl.formatMessage({ id: 'app.top_customers' }),
            data: values,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
          },
        ],
      };
      setData(templateData);
    }
  };

  return (
    <Fragment>
      <Bar data={data?.datasets?.length ? data : { datasets: [] }} options={options} />
    </Fragment>
  );
};
