import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { getSellerSalesCustomersProductsMetrics } from "../../../../api/SellerSalesCustomersProducts";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  SalesDataAnalystContainer,
  TitleInBox,
  FiltersContainer,
  FilterInput,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
} from "./styles";

export const SalesDataAnalyst = ({ date }) => {
  const { sellerData } = useSeller();
  const [salesData, setSalesData] = useState([]);
  const [filters, setFilters] = useState({ customer: "" });

  useEffect(() => {
    if (date) {
      fetchSalesData();
    }
  }, [date]);

  const fetchSalesData = async () => {
    try {
      const response = await getSellerSalesCustomersProductsMetrics(
        sellerData.user_id,
        date,
        sellerData.idr
      );
      console.log("SalesDataAnalyst API Data:", response.data.data.sales);
      setSalesData(response.data.data.sales);
    } catch (error) {
      console.error("Error al cargar los datos", error);
    }
  };

  // Filtro por nombre del cliente
  const filteredData = salesData.filter(({ name }) =>
    name.toLowerCase().includes(filters.customer.toLowerCase())
  );

  return (
    <SalesDataAnalystContainer>
      <TitleInBox>
        <FormattedMessage
          id="app.title_sales_data_analyst"
          defaultMessage="Producto más comprado por cliente"
        />
      </TitleInBox>

      {/* Filtros */}
      <FiltersContainer>
        <FilterInput
          type="text"
          placeholder="Filtrar por cliente"
          value={filters.customer}
          onChange={(e) =>
            setFilters({ ...filters, customer: e.target.value })
          }
        />
      </FiltersContainer>

      {/* Tabla */}
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Cliente</TableHeader>
              <TableHeader>Producto</TableHeader>
              <TableHeader>Cantidad</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {filteredData.map(({ id, name, products }) => (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{products[0]?.product}</TableCell>
                <TableCell>{products[0]?.quantity}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </SalesDataAnalystContainer>
  );
};
