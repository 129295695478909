import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getTopSellingProductsMetrics } from "../../../../api/TopSellingProductsService";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  TopSellingProductsContainer,
  TitleInBox,
  DataGraphWrapper,
  DataContainer,
  GraphContainer,
  BarChartContainer,
  BarChartBar,
  BarChartLabel,
  ProductCard,
  ProductImage,
  ProductInfo,
  ProductName,
  ProductSales,
} from "./styles";

export const TopSellingProducts = ({ date }) => {

  const [topSellingProducts, setTopSellingProducts] = useState([]);
  console.log(date);
  const { sellerData } = useSeller();

  useEffect(() => {
    if (date) {
      loadTopSellingProductsDataMetrics();
    }
  }, [date]);

  const loadTopSellingProductsDataMetrics = async () => {
    try {
      const response = await getTopSellingProductsMetrics(sellerData.user_id, date,sellerData.idr);
      setTopSellingProducts(response.data.data.products);
      console.log(response);
    } catch (error) {
      console.log("loadTopSellingProductsDataMetrics error", error);
    }
  };

  const maxSales = Math.max(...topSellingProducts.map((product) => product.total_quantity), 0);

  return (
    <TopSellingProductsContainer>
      <TitleInBox>
        <FormattedMessage id="app.title_top_selling_products" defaultMessage="Productos más vendidos" />
      </TitleInBox>
      <DataGraphWrapper>
        <DataContainer>
          {topSellingProducts.map((product) => (
            <ProductCard key={product.product_reference}>
              {/* <ProductImage src={product.image} alt={product.name} /> */}
              <ProductInfo>
                <ProductName>{product.product_name}</ProductName>
                <ProductSales>
                  <CurrencyFormat
                    value={product.total_quantity}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </ProductSales>
              </ProductInfo>
            </ProductCard>
          ))}
        </DataContainer>
        <GraphContainer>
          {topSellingProducts.map((product) => (
            <BarChartContainer key={product.product_reference}>
              <BarChartBar width={`${(product.total_quantity / maxSales) * 100}%`} />
              <BarChartLabel>
                {product.name} (
                <CurrencyFormat
                  value={product.total_quantity}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                )
              </BarChartLabel>
            </BarChartContainer>
          ))}
        </GraphContainer>
      </DataGraphWrapper>
    </TopSellingProductsContainer>
  );
};
