import { requestHttp } from "../utils/HttpRequest";

export const getTopSellingProductsMetrics = (idUser, date,idr) => {
  try {
    const response = requestHttp({
      endpoint: "top_selling_products_metrics",
      data: {
        user_id: idUser,
        chosen_date: date,
        idr:idr,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
