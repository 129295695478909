import React, { useState } from "react";
import "./styles.css";

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState("dashboard");

  return (
    <div>
      <div className="tabs">
        <button
          onClick={() => setActiveTab("dashboard")}
          className={activeTab === "dashboard" ? "active" : ""}
        >
          Dashboard
        </button>
        <button
          onClick={() => setActiveTab("graficos")}
          className={activeTab === "graficos" ? "active" : ""}
        >
          Graficos
        </button>
        <button
          onClick={() => setActiveTab("reportes")}
          className={activeTab === "reportes" ? "active" : ""}
        >
          Reportes
        </button>
      </div>

      <div className="tab-content">
        {React.Children.map(children, (child) => {
          if (child.props.tab === activeTab) {
            return child;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Tabs;
