import styled from "styled-components";

export const TopClientsBySellerContainer = styled.section`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const TitleInBox = styled.h3`
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
`;

export const MetricsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Metric = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  span {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 5px;
  }

  .currency {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const BarChartContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const BarChartBar = styled.div`
  height: 20px;
  width: ${(props) => props.width || "0%"};
  background: linear-gradient(90deg, #2196f3, #64b5f6);
  border-radius: 4px;
`;

export const BarChartLabel = styled.span`
  font-size: 0.9em;
  color: #333;
`;

export const ClientCard = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ClientName = styled.span`
  font-size: 1em;
  font-weight: 600;
  color: #333;
`;

export const ClientSales = styled.div`
  display: flex;
  gap: 10px;
  font-size: 0.9em;
  color: #666;

  span {
    font-weight: 500;
  }
`;