import styled from "styled-components";

export const SellerSalesTrackerContainer = styled.section`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const TitleInBox = styled.h3`
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
`;

export const MetricsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Metric = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  span {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 5px;
  }

  .currency {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
`;

export const LineChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-left: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
`;

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const DataPoint = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border-radius: 50%;
  left: ${(props) => props.x}%;
  bottom: ${(props) => props.y}%;
  transform: translate(-50%, 50%);
`;

export const Axis = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  justify-content: space-between;
  align-items: ${(props) => (props.horizontal ? "flex-end" : "flex-start")};
  font-size: 0.8em;
  color: #666;
  ${(props) =>
    props.horizontal
      ? `
          width: 100%;
          padding-top: 10px;
          border-top: 1px solid #eee;
        `
      : `
          height: 100%;
          padding-right: 10px;
          border-right: 1px solid #eee;
        `}
`;