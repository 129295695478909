import styled from "styled-components";

export const GoalsContainer = styled.section`
  margin: 30px 0 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const SalesGoalsContainer = styled.section`
  flex: 1;
  display: flex;
  gap: 20px;
`;

export const CollectionGoalsContainer = styled.section`
  flex: 1;
  display: flex;
  gap: 20px;
`;

export const TitleGoals = styled.h3`
  margin: 10px 0;
  padding: 0px;
  font-size: 1.05em;
  font-weight: 700;
`;

export const BarChartContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BarChartBar = styled.div`
  height: 20px;
  width: ${(props) => props.width || "0%"};
  background-color: #4caf50;
  margin-bottom: 5px;
`;

export const BarChartLabel = styled.span`
  font-size: 0.9em;
  color: #333;
`;