import styled from "styled-components";

export const TopProductsContainer = styled.section`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const TitleInBox = styled.h3`
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ProductName = styled.span`
  font-size: 1em;
  font-weight: 600;
  color: #333;
`;

export const ProductQuantity = styled.span`
  font-size: 0.9em;
  color: #666;
`;

export const ProductSales = styled.span`
  font-size: 1.1em;
  font-weight: 600;
  color: #333;
`;