import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getOrderSalesMetrics } from "../../../../api/OrderSalesMetricsService";
import { getToSellingCustomersMetrics } from "../../../../api/TopSellingCustomers";
import { useLangApp } from "../../../../contexts/LangContext";
import { useSeller } from "../../../../contexts/SellerContext";
import { Label, TitleInBox, ValueLabel } from "../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";
import {
  TopCustomersContainer,
  DataGraphWrapper,
  DataContainer,
  GraphContainer,
  DonutChart,
  DonutLabel,
  LegendContainer,
  LegendItem,
  LegendColor,
} from "./styles";

export const TopCustomersDonutChart = ({ date }) => {
  const { sellerData } = useSeller();
  const { currentLang } = useLangApp();
  const [orderSalesData, setOrderSalesData] = useState(null);
  const [topSellingData, setTopSellingData] = useState([]);

  useEffect(() => {
    if (date) {
      loadOrderSalesDataMetrics();
      loadTopSellingCustomersDataMetrics();
    }
  }, [date]);

  const loadOrderSalesDataMetrics = async () => {
    try {
      const response = await getOrderSalesMetrics(sellerData.user_id, date);
      setOrderSalesData(response.data.data);
    } catch (error) {
      console.log("loadOrderSalesDataMetrics error", error);
    }
  };

  const loadTopSellingCustomersDataMetrics = async () => {
    try {
      const response = await getToSellingCustomersMetrics(
        sellerData.user_id,
        date
      );
      setTopSellingData(response.data.data.customers);
    } catch (error) {
      console.log("loadTopSellingCustomersDataMetrics error", error);
    }
  };

  // Calcular el total de ventas para escalar los segmentos
  const totalSales = topSellingData.reduce(
    (sum, customer) => sum + customer.total_sales,
    0
  );

  // Colores para los segmentos del gráfico
  const colors = ["#4caf50", "#2196f3", "#ff9800", "#e91e63", "#9c27b0"];

  // Generar el gradiente para el gráfico de donut
  const generateDonutGradient = () => {
    let gradient = "";
    let startAngle = 0;

    topSellingData.forEach((customer, index) => {
      const percentage = (customer.total_sales / totalSales) * 100;
      gradient += `${colors[index % colors.length]} ${startAngle}% ${
        startAngle + percentage
      }%, `;
      startAngle += percentage;
    });

    return gradient.slice(0, -2); // Eliminar la última coma y espacio
  };

  return (
    <TopCustomersContainer>
      <TitleInBox>
        <FormattedMessage id="app.title_top_sales_customers" />
      </TitleInBox>
      <DataGraphWrapper>
        <DataContainer>
          <br />
          <ValueLabel>
            <CurrencyFormat
              value={
                orderSalesData?.total_sales_month !== null
                  ? orderSalesData?.total_sales_month
                  : 0
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </ValueLabel>
          <Label>
            <FormattedMessage id="app.total_sales_on" />
            <strong>{` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}</strong>
          </Label>
          <br />
          <ValueLabel>{orderSalesData?.total_units}</ValueLabel>
          <Label>
            <FormattedMessage id="app.total_sales_items_on" />
            <strong>{` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}</strong>
          </Label>
        </DataContainer>
        <GraphContainer>
          <DonutChart gradient={generateDonutGradient()}>
            <DonutLabel>
              {/* <FormattedMessage id="app.total_sales" /> */}
              <br />
              <CurrencyFormat
                value={totalSales}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </DonutLabel>
          </DonutChart>
          <LegendContainer>
            {topSellingData.map((customer, index) => (
              <LegendItem key={index}>
                <LegendColor color={colors[index % colors.length]} />
                {customer.customer_name} (
                <CurrencyFormat
                  value={customer.total_sales}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                )
              </LegendItem>
            ))}
          </LegendContainer>
        </GraphContainer>
      </DataGraphWrapper>
    </TopCustomersContainer>
  );
};