import styled from "styled-components";

export const TopSellingProductsContainer = styled.section`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const TitleInBox = styled.h3`
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
`;

export const DataGraphWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const DataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const GraphContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductName = styled.span`
  font-size: 1em;
  font-weight: 600;
  color: #333;
`;

export const ProductSales = styled.span`
  font-size: 0.9em;
  color: #666;
`;

export const BarChartContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BarChartBar = styled.div`
  height: 20px;
  width: ${(props) => props.width || "0%"};
  background: linear-gradient(90deg, #4caf50, #81c784);
  border-radius: 4px;
`;

export const BarChartLabel = styled.span`
  font-size: 0.9em;
  color: #333;
`;