import { requestHttp } from "../utils/HttpRequest";

export const getTopSellingProductsCatalogMetrics = (idUser, date,idr) => {
  try {
    const response = requestHttp({
      endpoint: "top_products_catalogs",
      data: {
        user_id: idUser,
        chosen_date: date,
        idr:idr,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
