import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getTopSellingProductsCatalogMetrics } from "../../../../api/TopSellingProductsCatalogService";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  TopSellingProductsCatalogContainer,
  TitleInBox,
  FiltersContainer,
  FilterInput,
  ProductList,
  ProductCard,
  ProductImage,
  ProductInfo,
  ProductName,
  ProductSales,
  ProductQuantity,
} from "./styles";

export const TopSellingProductsCatalog = ({ date }) => {
  const { sellerData } = useSeller();
  const [catalogs, setCatalogs] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (date) {
      fetchTopSellingProducts();
    }
  }, [date]);

  const fetchTopSellingProducts = async () => {
    try {
      setLoading(true);
      const response = await getTopSellingProductsCatalogMetrics(
        sellerData.user_id,
        date,
        sellerData.idr
      );
      console.log("Top Selling Products:", response.data?.data?.catalogs);
      setCatalogs(response.data?.data?.catalogs);
    } catch (error) {
      console.error("Error al cargar los productos más vendidos:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredCatalogs = catalogs.map((catalog) => ({
    ...catalog,
  }));

  return (
    <TopSellingProductsCatalogContainer>
      <TitleInBox>
        <FormattedMessage
          id="app.title_top_selling_products_catalog"
          defaultMessage="Catálogo de productos más vendidos"
        />
      </TitleInBox>

      {/* Filtros */}
      <FiltersContainer>
        <FilterInput
          type="text"
          placeholder="Filtrar por nombre de producto"
          value={filters.category}
          onChange={(e) =>
            setFilters({ ...filters, category: e.target.value })
          }
        />
      </FiltersContainer>

      {/* Cargando */}
      {loading && <p>Cargando productos...</p>}

      {/* Lista de productos */}
      {filteredCatalogs.map(({ name, products }) => (
        <div key={name}>
          <h2>{name}</h2>
          <ProductList>
            {products.map(({ id, product, quantity, total_sales }) => (
              <ProductCard key={id}>
                <ProductInfo>
                  <ProductName>{product}</ProductName>
                  <ProductQuantity>
                    <span>{quantity} unidades vendidas</span>
                  </ProductQuantity>
                  <ProductSales>
                    <CurrencyFormat
                      value={total_sales}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </ProductSales>
                </ProductInfo>
              </ProductCard>
            ))}
          </ProductList>
        </div>
      ))}
    </TopSellingProductsCatalogContainer>
  );
};