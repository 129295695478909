import styled from "styled-components";

export const SalesDataAnalystContainer = styled.section`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto; /* Este es el que centra */
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  align-items: center; /* Ahora sí va a centrar todo */
`;



export const TitleInBox = styled.h3`
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%; /* Añade esto para que ocupe todo el ancho */
  justify-content: space-between; /* Distribuye los filtros */
`;


export const FilterInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 0.9em;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background: #f9f9f9;
  border-bottom: 2px solid #ccc;
  font-size: 0.9em;
  color: #333;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 0.9em;
  color: #333;
`;