import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getSellerSalesTrackerMetrics } from "../../../../api/SellerSalesTrackerService";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  SellerSalesTrackerContainer,
  TitleInBox,
  MetricsContainer,
  Metric,
  ChartContainer,
  LineChart,
  Line,
  Axis,
  DataPoint,
} from "./styles";

export const SellerSalesTracker = ({ date }) => {
  console.log("SellerSalesTracker", date);
  const { sellerData } = useSeller();
  const [seller, setSeller] = useState({ sales: [], labels: [] });

  useEffect(() => {
    console.log("SellerSalesTracker", date);
    if (date) {
      loadSellerSalesTrackerData();
    }
  }, [date]);

  const loadSellerSalesTrackerData = async () => {
    try {
      const response = await getSellerSalesTrackerMetrics(sellerData.user_id, date, sellerData.idr);
      setSeller(response.data.data.seller);
      console.log("seller", response.data.data.seller);
    } catch (error) {
      console.log("loadSellerSalesTrackerData error", error);
    }
  };

  const labels = seller.labels || [];
  const totalSales = seller.sales.reduce((sum, sale) => sum + sale, 0);
  const averageSales = seller.sales.length ? totalSales / seller.sales.length : 0;
  const trend = seller.sales.length ? seller.sales[seller.sales.length - 1] - seller.sales[0] : 0;
  const maxSales = Math.max(...seller.sales, 0);
  const minSales = Math.min(...seller.sales, 0);
  const margin = (maxSales - minSales) * 0.1;

  return (
    <SellerSalesTrackerContainer>
      <MetricsContainer>
        <Metric>
          <span>Total de ventas</span>
          <CurrencyFormat value={totalSales} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Metric>
        <Metric>
          <span>Promedio semanal</span>
          <CurrencyFormat value={averageSales} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Metric>
        <Metric>
          <span>Tendencia</span>
          <CurrencyFormat
            value={trend}
            displayType={"text"}
            thousandSeparator={true}
            prefix={trend >= 0 ? "+$" : "-$"}
          />
        </Metric>
      </MetricsContainer>
      <ChartContainer>
        <LineChart>
          <Axis>
            {[...Array(5)].map((_, i) => {
              const value = maxSales - (i * ((maxSales - minSales + margin) / 4));
              return <span key={i}>${value.toFixed(2)}</span>;
            })}
          </Axis>
          <Line>
            {seller.sales.map((sale, i) => (
              <DataPoint key={i} x={(i / (seller.sales.length - 1)) * 100} y={100 - (sale / maxSales) * 100} />
            ))}
          </Line>
          <Axis horizontal>
            {labels.map((label, i) => (
              <span key={i}>{label}</span>
            ))}
          </Axis>
        </LineChart>
      </ChartContainer>
    </SellerSalesTrackerContainer>
  );
};