import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getTopClientsBySeller } from "../../../../api/TopClientsBySellerService";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  TopClientsBySellerContainer,
  TitleInBox,
  MetricsContainer,
  Metric,
  ChartContainer,
  BarChartContainer,
  BarChartBar,
  BarChartLabel,
  ClientCard,
  ClientInfo,
  ClientName,
  ClientSales,
} from "./styles";

export const TopClientsBySeller = ({ date }) => {
  const { sellerData } = useSeller();
  const [topClients, setTopClients] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (date) {
      loadTopClientsData();
    }
  }, [date]);

  const loadTopClientsData = async () => {
    try {
      setLoading(true); // Mostrar cargando
      const response = await getTopClientsBySeller(sellerData.user_id, date, sellerData.idr);
      const { topClients } = response.data.data;

      const totalSales = topClients.reduce((sum, client) => sum + client.totalPurchases, 0);
      const totalClients = topClients.length;

      setTopClients(topClients);
      setTotalSales(totalSales);
      setTotalClients(totalClients);
    } catch (error) {
      console.log("Error fetching top clients data:", error);
    } finally {
      setLoading(false); // Ocultar cargando
    }
  };

  return (
    <TopClientsBySellerContainer>
      <TitleInBox>
        <FormattedMessage id="app.title_top_clients_by_seller" defaultMessage="Clientes que más compran" />
      </TitleInBox>

      {/* Métricas clave */}
      <MetricsContainer>
        <Metric>
          <span>Total de ventas</span>
          <CurrencyFormat value={totalSales} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Metric>
        <Metric>
          <span>Clientes activos</span>
          <span>{totalClients}</span>
        </Metric>
      </MetricsContainer>

      {/* Spinner de carga */}
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          🔄 Cargando información...
        </div>
      ) : topClients.length === 0 ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          ⚠️ No hay datos disponibles
        </div>
      ) : (
        <>
          {/* Gráfico de barras */}
          <ChartContainer>
            {topClients.map((client) => (
              <BarChartContainer key={client.id}>
                <BarChartBar width={`${(client.totalPurchases / totalSales) * 100}%`} />
                <BarChartLabel>
                  {client.name} (
                  <CurrencyFormat
                    value={client.totalPurchases}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  )
                </BarChartLabel>
              </BarChartContainer>
            ))}
          </ChartContainer>

          {/* Tarjetas de clientes */}
          <div>
            {topClients.map((client) => (
              <ClientCard key={client.id}>
                <ClientInfo>
                  <ClientName>{client.name}</ClientName>
                  <ClientSales>
                    <CurrencyFormat
                      value={client.totalPurchases}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <span>{client.transactions} transacciones</span>
                  </ClientSales>
                </ClientInfo>
              </ClientCard>
            ))}
          </div>
        </>
      )}
    </TopClientsBySellerContainer>
  );
};
